<div class="d-flex justify-content-between bd-highlight mb-3">
    <div class="p-2">
        <button (click)="goBack()" class="btn btn-outline-secondary btn-lg px-4">Go
            Back</button>
    </div>
    <div class="p-2">
        <button (click)="addRecipeToList(recipe)" *ngIf="isSignedIn" class="btn btn-primary btn-lg px-4 me-sm-3">Add to
            your
            list</button>
        <button (click)="addRecipeToShoppingList(recipe)" *ngIf="isSignedIn"
            class="btn btn-primary btn-lg px-4 me-sm-3">Add Ingredients to shopping list</button>
        <button routerLink="/register" *ngIf="!isSignedIn" class="btn btn-primary btn-lg px-4 me-sm-3">Register to add
            to list</button>
    </div>
</div>
<section class="py-5 text-center container">
    <div class="col-lg-8 mx-auto">
        <!-- This is localhost, so I added only share from source url, otherwise I cant use: {{currentRoute}} -->
        <share-buttons [theme]="'material-dark'"
            [include]="['facebook','twitter','linkedin','pinterest','reddit','mix','vk','telegram','messenger','whatsapp','xing','line','sms','email','copy']"
            [show]="9" [size]="1" [url]="recipe?.sourceUrl" [image]="recipe?.image" [autoSetMeta]="false">
        </share-buttons>
        <img class="d-block mx-auto mb-4" [src]="recipe?.image">
        <h1 class="fw-light">{{ recipe?.title }}</h1>
        <p class="lead text-muted" [innerHTML]="recipe?.summary"></p>
    </div>
</section>

<div class="row g-5">
    <div class="col-md-5 col-lg-4">
        <h4 class="d-flex justify-content-between align-items-center mb-3">Ingredients</h4>
        <ul class="list-group mb-3">
            <li class="list-group-item d-flex justify-content-between lh-sm bg-light"
                *ngFor="let item of recipe?.extendedIngredients">{{ item?.original }}</li>
        </ul>
    </div>
    <div class="col-md-7 col-lg-8">
        <h5 class="mb-3">How to?</h5>
        <p [innerHTML]="recipe?.instructions"></p>
    </div>
</div>