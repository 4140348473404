<div class="container">
    <div class="card inner-main">
        <div class="card-header">
            User Profile
        </div>
        <div class="card-body">
            <p class="card-text">Name: <strong>{{UserProfile?.name}}</strong></p>
            <p class="card-text">Email: <strong>{{UserProfile?.email}}</strong></p>
        </div>
    </div>
</div>